<nav class="col-md-2 d-none d-md-block bg-light sidebar">
    <div class="col sidebar-sticky pl-1">
        <div class="row mb-3">
            <div class="col">
                <select class="form-control form-control-dropdown" [(ngModel)]="siteId" (change)="setSiteId();" [disabled]="disableSiteSelectList">
                    <option value="">- Select site -</option>
                    <optgroup *ngFor='let group of sites' label="{{group[0].customerName}}">
                      <option *ngFor='let site of group' [value]="site.id">{{site.name}}</option> 
                    </optgroup>
                </select>
            </div>
        </div>
        <!-- Content -->
        <h6 *ngIf="siteId !== '' && (authenticatedUserRoles.indexOf('Super') >= 0 || authenticatedUserRoles.indexOf('Administrator') >= 0 || authenticatedUserRoles.indexOf('Author') >= 0)" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <a class="menuItemHeader" [class.active]="collapsedMenu === 'content'" href="#contentMenu" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="contentMenu">Content <i class="fa fa-caret-down"></i></a>
        </h6>
        <div *ngIf="siteId !== '' && (authenticatedUserRoles.indexOf('Super') >= 0 || authenticatedUserRoles.indexOf('Administrator') >= 0 || authenticatedUserRoles.indexOf('Author') >= 0)" id="contentMenu" class="collapse" [class.show]="collapsedMenu === 'content'">
            <ul class="nav flex-column mb-2 ml-2">
                <li class="nav-item d-sm-inline-flex pl-3 row">
                    <div class="col-1 mt-auto mb-auto pr-2 align-self-center">
                        <i class="fa fa-exclamation-triangle"></i>
                    </div>
                    <div class="col pl-1">
                        <a class="nav-link" href="#" [class.active]="isRouteActive(['/emergency-announcement'])" [routerLink]="['/emergency-announcement']">Emergency announcements</a>
                    </div>
                </li>
                <li class="nav-item d-sm-inline-flex pl-3 row">
                    <div class="col-1 mt-auto mb-auto pr-2 align-self-center">
                        <i class="fa fa-bullhorn"></i>
                    </div>
                    <div class="col pl-1">
                        <a class="nav-link" href="#" [class.active]="isRouteActive(['/general-announcement'])" [routerLink]="['/general-announcement']">General announcements</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
