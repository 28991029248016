export class AuthStatus {
    constructor(isValid: boolean, showError: boolean = false, reason: string = '', errorTimeout: number = 5000) {
        this.isValid = isValid;
        this.reason = reason;
        this.showError = showError;
        this.errorTimeout = errorTimeout;
    }

    isValid: boolean;
    reason: string;
    showError: boolean;
    errorTimeout: number;
}