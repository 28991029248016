<!--Public-->
<topbar></topbar>
<div class="justify-content-center pt-3">
    <div class="row mb-5"></div>
    <div class="mt-3">
        <form class="form-signin" [formGroup]="loginForm" (submit)="login($event)">
            <img id="login-logo-rgroup" src="../../assets/images/big_rgroup_logo.png" alt="R-Group" />
            <h4 class="form-signin-heading">Welcome to the {{this.applicationName}}</h4>
            <div style="position: relative;">
                <ngx-spinner bdColor="rgba(255,255,255,0.00)" size="medium" color="#312A2A" type="ball-clip-rotate-multiple" [fullScreen] = "false"></ngx-spinner>
            </div>
            <input type="text" formControlName="userName" name="userName" class="form-control" placeholder="Username" />
            <input type="password" formControlName="password" name="password" class="form-control" placeholder="Password" />
            <button [disabled]="isBusy" class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
        </form>
    </div>
</div>
