import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.css']
})
export class FormButtonsComponent implements OnInit {
  /** Form button events */
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() canDelete = true;
  @Input() canSave = true;
  @Input() canSaveClose = true;
  @Input() canSaveCreate = true;
  @Input() isEdit: boolean;

  constructor() { }

  /** Init. */
  ngOnInit(): void { }

  /** Save. */
  save(): void {
    this.submitEvent.emit({ type: '' });
  }

  /** Save and Create. */
  SaveCreate(): void {
    this.submitEvent.emit({ type: 'SaveCreate' });
  }

  /** Save and Close. */
  saveAndClose(): void {
    this.submitEvent.emit({ type: 'SaveClose' });
  }

  /** Close. */
  close(): void {
    this.closeEvent.emit();
  }

  /** Delete. */
  delete(): void {
    this.deleteEvent.emit();
  }
}
