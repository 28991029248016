import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // Check if a user is authenticated.
        var authenticated = this.authService.isAuthenticated();
        
        if (!authenticated.isValid) {
            return true;
        }

        this.router.navigate(['/']);
        
        return false;
    }
}
