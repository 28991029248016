<form [formGroup]="announcementForm" *ngIf="(isEdit && announcementModel) || !isEdit">
    <div class="pb-2 border-bottom">
        <form-buttons [isEdit]="isEdit" (submitEvent)="save($event)" (deleteEvent)="delete()" (closeEvent)="close()"></form-buttons>
        <button *ngIf="!isPublished" (click)="togglePublishAndSave()" name="button" title="Publish" class="btn btn-light mr-1"><img src="../../../../assets/images/icons/publish.png" alt="Publish" /> Publish</button>
        <button *ngIf="isPublished" (click)="togglePublishAndSave()" name="button" title="Unpublish" class="btn btn-light mr-1"><img src="../../../../assets/images/icons/unpublish.png" alt="Unpublish" /> Unpublish</button>
        <button title="Preview" class="btn btn-light" (click)="showPreview()"><img src="../../../../assets/images/icons/preview.png" alt="Preview" /> Preview</button>
    </div>
    <br />
    <div class="row mb-3">
        <div class="col">
            <label class="required">Title</label>
            <span *ngIf="validations" class="validation-summary-errors field-validation-error">{{validations.Title?.lastItem}}</span>
            <input type="text" formControlName="title" name="title" class="form-control" />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <label class="required">Announcement</label>
            <span *ngIf="validations" class="validation-summary-errors field-validation-error">{{validations.Body?.lastItem}}</span>
            <textarea id="body" formControlName="body" name="body" id="tinyMceBody" class="form-control"></textarea>
        </div>
    </div>
    <div *ngIf="type==='emergency' && alertColours" class="row mb-3">
        <div class="col">
            <label>Alert colour</label>
            <select class="form-control form-control-dropdown" formControlName="alertColour" name="alertColour">
                <option *ngFor="let s of alertColours" [ngValue]="s.id">{{s.name}}</option>
            </select>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <label class="required">Start date/time</label>
            <span *ngIf="validations" class="validation-summary-errors field-validation-error">{{validations.StartDateTime?.lastItem}}</span>
            <br />
            <input type="text" formControlName="startDateTime" name="startDateTime" class="form-control form-control-datetime" style="display: inline; margin-right: 5px;" readonly />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <label class="required">End date/time</label>
            <span *ngIf="validations" class="validation-summary-errors field-validation-error">{{validations.EndDateTime?.lastItem}}</span>
            <br />
            <input type="text" formControlName="endDateTime" name="endDateTime" class="form-control form-control-datetime" style="display: inline; margin-right: 5px;" readonly />
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <label>Published</label>
            <br>
            <img *ngIf="!isEdit" title="Unpublished" src="../../../../assets/images/icons/cross.png">
            <img *ngIf="isEdit" title="{{announcementModel.statusTitle}}" src="../../../../assets/images/icons/{{announcementModel.statusImage}}">
            
        </div>
    </div>
    <div class="row" *ngIf="isEdit">
        <div class="col">
            <label>Last modified</label>
            <input type="text" formControlName="modifiedDateFormatted" name="modifiedDateFormatted" id="ModifiedDateFormatted" class="form-control" readonly />
        </div>
    </div>
</form>
