import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: '[sorting]',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.css']
})
export class SortingComponent implements AfterContentInit {
  @Output() searchEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() column: any;
  @Input() sortBy: any;
  @Input() sortingDirection = 0;

  public currentSorting: any;
  public currentSortingDirection = 0;

  /** After view init. */
  ngAfterContentInit(): void {
    this.currentSorting = this.sortBy;
    this.currentSortingDirection = this.sortingDirection;
  }

  /** Triggers the parent fetch method. */
  sort(sortKey: string): void {
    if (sortKey === this.currentSorting.lastItem) {
      this.currentSortingDirection = this.currentSortingDirection === 1 ? 0 : 1;
    }
    else {
      /** Default sort to ascending. */
      this.currentSortingDirection = 0;
    }
    this.currentSorting = sortKey;
    if (this.searchEvent !== undefined) {
      this.searchEvent.emit({ sortBy: sortKey, sortDirection: this.currentSortingDirection });
    }
  }
}
