import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRestService } from './rest.service';

//AppConfig.d.ts
export interface AppConfig {
    apiUrl: string;
}

//AppConfigService.ts
@Injectable()
export class AppConfigService {
    private config: AppConfig | undefined;
    loaded = false;
    constructor(protected http: HttpClient) { }
    
    loadConfig(): Promise<void> {
        return this.http
            .get<AppConfig>('./assets/config.json')
            .toPromise()
            .then(data => {
                this.config = data;
                this.loaded = true;
            }, (e) => {
                console.log("error with config");
            });
    }
    
    getConfig(): AppConfig | undefined {
        return this.config;
    }
}