<div class="panel">
    <div class="border-bottom">
        <h4>{{this.menuTitle}} : {{this.controllerTitle}} : {{this.pageTitle}}</h4>
    </div>
    <div class="mt-2">
        <announcement-form *ngIf="announcementModel" [announcementModel]="announcementModel" [isEdit]="true"
            [type]="'general'" [validations]="validations" (submitEvent)="update($event)" (closeEvent)="close()"
            (deleteEvent)="delete($event)">
        </announcement-form>
    </div>
</div>