import { Pipe, PipeTransform } from '@angular/core';

/** Transforms a comma separated string to array.  */
@Pipe({ name: 'commaseparatestringtoarray' })
export class CommaSeparateStringToArrayPipe implements PipeTransform {

  transform(stringToSplit: string): string[] {
    if (stringToSplit === 'undefined' || stringToSplit === '') { return ['']; }

    return stringToSplit.split(',').map((item) => item.trim());
  }
}
