import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'modal-preview-announcement',
  templateUrl: './modal-preview-announcement.component.html',
  styleUrls: ['./modal-preview-announcement.component.css']
})

export class ModalPreviewAnnouncementComponent implements OnInit {
  /** cTor. */
  constructor(public activeModal: NgbActiveModal) { }

  title: string;
  body: string;
  titleClass: string;
  /** Init. */
  ngOnInit(): void {
    $(document).ready(() => {
      const modalContent: any = $('.modal-content');
      modalContent.draggable({
        handle: '.modal-header',
        revert: true,
        revertDuration: 300
      });
    });
  }
}
