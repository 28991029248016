<div class="panel">
    <div class="border-bottom">
        <h4>{{this.menuTitle}} : {{this.controllerTitle}}</h4>
    </div>
    <div *ngIf="siteId === ''" class="alert alert-secondary text-center">
        <h5>Please select a site</h5>
    </div>
    <div *ngIf="siteId !== ''">
        <div class="row mt-2 pb-1 mb-1">
            <div class="col-sm-5 pr-0 pt-0">
                <a class="btn btn-light" [routerLink]="['/emergency-announcement/create']"><img src="../../assets/images/icons/create.png" alt="Create" /> Create</a>
            </div>
            <div class="col-7">
                <filtering *ngIf="pagedEmergencyAnnouncements" [searchColumns]="pagedEmergencyAnnouncements.filtering.searchColumns"
                           [operators]="pagedEmergencyAnnouncements.filtering.operators"
                           (searchEvent)="paged($event)"
                           (clearEvent)="clear()"
                           (filterKeyChangeEvent)="filterKeyChangeEvent($event)"
                           (filterOperatorChangeEvent)="filterOperatorChangeEvent($event)"
                           [defaultSearch]="'Title'"
                           [defaultOperator]="'CaseInsensitiveContains'"
                           [customExclusions]="{'StartDateTime': [ 'CaseInsensitiveContains', 'CaseInsensitiveDoesNotContain', 'CaseInsensitiveEndsWith', 'CaseInsensitiveStartsWith', 'IsEmpty', 'NotIsEmpty'], 
                           'EndDateTime': [ 'CaseInsensitiveContains', 'CaseInsensitiveDoesNotContain', 'CaseInsensitiveEndsWith', 'CaseInsensitiveStartsWith', 'IsEmpty', 'NotIsEmpty']}"
                           [fieldsForExclusion]="['Body']">
                </filtering>
            </div>
        </div>
        <table class="table table-striped">
            <thead>
                <tr *ngIf="pagedEmergencyAnnouncements">
                    <th *ngFor="let column of pagedEmergencyAnnouncements.filtering.sorting.columnsArray"
                        sorting [column]="column"
                        [sortBy]="pagedEmergencyAnnouncements.filtering.sorting.orderBy | commaseparatestringtoarray"
                        [sortingDirection]="pagedEmergencyAnnouncements.filtering.sorting.sortDirection"
                        (searchEvent)="paged($event)">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="pagedEmergencyAnnouncements && pagedEmergencyAnnouncements.items.length === 0">
                    <td class="no-record-message" colspan="6"><h5>No records to show</h5></td>
                </tr>
                <ng-container *ngIf="pagedEmergencyAnnouncements && pagedEmergencyAnnouncements.items.length > 0">
                    <tr *ngFor="let announcement of pagedEmergencyAnnouncements.items | paginate: { itemsPerPage: pagedEmergencyAnnouncements.paging.pageSize, currentPage: pagedEmergencyAnnouncements.paging.currentPage, totalItems: pagedEmergencyAnnouncements.totalItems }">
                        <td class="edit-button" ><a href="#" [routerLink]="['./edit', announcement.id]" title="Edit the general announcement for '{{announcement.title}}'">{{announcement.title}}</a></td>
                        <td>{{announcement.bodyFormatted}}</td>
                        <td>{{announcement.alertColourFormatted}}</td>
                        <td class="col-date">{{announcement.startDateTime | date: 'dd MMM yyyy hh:mm a'}}</td>
                        <td class="col-date">{{announcement.endDateTime | date: 'dd MMM yyyy hh:mm a'}}</td>
                        <td><img src="../../assets/images/icons/{{announcement.statusImage}}" title="{{announcement.statusTitle}}" alt="{{announcement.statusTitle}}" /></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div *ngIf="pagedEmergencyAnnouncements && pagedEmergencyAnnouncements.items.length > 0" class="row justify-content-center">
            <pagination-controls previousLabel="" nextLabel="" responsive="true" (pageChange)="onPageChange($event)"></pagination-controls>
        </div>
    </div>
</div>
