import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  applicationName: string;
    isAuthenticated: boolean;
    username: string;

    /** cTor */
    constructor(private authService: AuthService, private toastr: ToastrService) {
        authService.sessionEvent.subscribe(res => {
            this.isAuthenticated = res.isAuthenticated;
            this.username = res.username;
        });
    }

    /** Init */
    ngOnInit(): void {
        var authenticated =  this.authService.isAuthenticated();
        
        this.isAuthenticated = authenticated.isValid;
        
        if (this.isAuthenticated) {
            this.username = this.authService.getAuthenticatedUser();
        }

        this.applicationName = environment.applicationName;
    }

    /** Clears the authentication */
    logout(): void {
        this.authService.logout();

        this.toastr.success('User logged out successfully.', null, { closeButton: true });
    }

}
