import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Enums } from 'src/app/enum/enums.component';
import { AnnouncementModel } from 'src/app/models/announcement.model';
import { CommonService } from 'src/app/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PublishStatus } from '../../../enum/publish-status.enum';
import { ModalPreviewAnnouncementComponent } from '../../modals/modal-preview-announcement/modal-preview-announcement.component';

declare var tinyMCE: any;
declare var $: any;

@Component({
  selector: 'announcement-form',
  templateUrl: './announcement-form.component.html',
  styleUrls: ['./announcement-form.component.css']
})
export class AnnouncementFormComponent implements OnInit {
  /** Form button events */
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() announcementModel: AnnouncementModel;
  @Input() customData: any;
  @Input() isEdit: boolean;
  @Input() type: string;
  @Input() validations: any;

  announcementForm: FormGroup;

  alertColours = new Array<any>();
  defaultSelectedColour = '#ffffff';
  isPublished: boolean;
  publishStatus: string;

  constructor(private commonService: CommonService, private enums: Enums, private formBuilder: FormBuilder, private modalService: NgbModal) { }

  /** After content init. */
  ngAfterContentInit(): void {
    /** Initialise tinyMCE textarea */
    $(document).ready(() => {
      tinyMCE.init({
        mode: 'textareas',
        theme: 'advanced',
        plugins: 'paste',
        paste_text_sticky: true,
        paste_text_sticky_default: true,
        gecko_spellcheck: true,
        theme_advanced_buttons1: 'bold,italic,underline,|,forecolor,|,pastetext,selectall',
        theme_advanced_buttons2: '',
        theme_advanced_buttons3: '',
        theme_advanced_toolbar_location: 'bottom',
        theme_advanced_toolbar_align: 'center',
        theme_advanced_resizing: false,
        table_row_limit: 5,
        oninit(e: any): void {
          tinyMCE.activeEditor.contentDocument.body.style.backgroundColor = '#000000';
          tinyMCE.activeEditor.contentDocument.body.style.color = '#ffffff';
          tinyMCE.activeEditor.contentDocument.body.style.overflow = 'hidden';
        },
        handle_event_callback(e: any): boolean {
          if (e.type === 'keydown') {
            tinyMCE.triggerSave();
            const content = tinyMCE.activeEditor.getContent();
            const strippedContent = tinyMCE.activeEditor.getContent().replace(/<[^>]*>?/g, '');

            if (strippedContent.length > 500) {
              alert('There is a limit of 500 characters allowed for an announcement.');

              tinyMCE.activeEditor.setContent(content.substring(0, 500));
            }

            return true;
          }
        }
      });
    });
  }

  /** After view init. */
  ngAfterViewInit(): void {
    /** Initialise jquery datetime picker */
    const minDate = new Date();
    minDate.setHours(0, 0);

    $('input[name=\'startDateTime\']').datetimepicker({
      showOn: 'button',
      buttonImage: '../../assets/images/icons/calendar.png',
      buttonImageOnly: true,
      buttonText: 'Select date/time',
      dateFormat: 'dd M yy',
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      minDate,
      timeFormat: 'h:mm TT',
      stepHour: 1,
      stepMinute: 5,
      ampm: true,
      onSelect: (dateText, inst) => {
        this.announcementForm.controls.startDateTime.setValue(dateText);
      }
    });

    $('input[name=\'endDateTime\']').datetimepicker({
      showOn: 'button',
      buttonImage: '../../assets/images/icons/calendar.png',
      buttonImageOnly: true,
      buttonText: 'Select date/time',
      dateFormat: 'dd M yy',
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      minDate,
      timeFormat: 'h:mm TT',
      stepHour: 1,
      stepMinute: 5,
      ampm: true,
      onSelect: (dateText, inst) => {
        this.announcementForm.controls.endDateTime.setValue(dateText);
      }
    });
  }

  /** Init. */
  ngOnInit(): void {
    if (this.type === 'emergency') {
      this.initialiseAlertColours();
    }

    if (!this.isEdit) {
      this.initialiseForm(true);
    }
    else if (this.isEdit && this.announcementModel !== undefined) {
      this.initialiseForm(true, this.announcementModel);
    }
  }

  /** Initialise form. */
  initialiseForm(isInitializing: boolean, announcementModel: AnnouncementModel = null): void {
    if (announcementModel === null) {
      if (!isInitializing) {
        tinyMCE.activeEditor.setContent('');
      }

      this.announcementForm = this.formBuilder.group({
        title: [''],
        body: [''],
        startDateTime: [''],
        endDateTime: [''],
        isEmergency: [this.type === 'emergency'],
        alertColour: [this.type === 'emergency' ? this.defaultSelectedColour : ''],
        isPublished: [false]
      });
    }
    else {
      this.announcementModel = announcementModel;
      this.isPublished = announcementModel.isPublished;
      this.publishStatus = PublishStatus[announcementModel.status];

      this.announcementForm = this.formBuilder.group({
        id: [announcementModel.id],
        body: [announcementModel.body],
        title: [announcementModel.title],
        startDateTime: [this.commonService.dateTimeToLocal(announcementModel.startDateTime)],
        endDateTime: [this.commonService.dateTimeToLocal(announcementModel.endDateTime)],
        isEmergency: [this.type === 'emergency'],
        alertColour: [announcementModel.alertColour],
        status: [this.publishStatus],
        isPublished: [announcementModel.isPublished],
        modifiedDateFormatted: [this.commonService.dateTimeToLocal(announcementModel.modifiedDate)]
      });
    }
  }

  /** Save. */
  save(buttonArgs: any): void {
    /**
     * Save the value of the input in the tinyMCE textarea. Get the value and set to content form.
     * This is a workaround as Angular cannot bind to the tinyMCE control.
     */
    tinyMCE.triggerSave();
    this.announcementForm.controls.body.setValue(tinyMCE.activeEditor.getContent());
    this.submitEvent.emit({ form: this.announcementForm.value, type: buttonArgs.type });
  }

  /** Close. */
  close(): void {
    this.closeEvent.emit();
  }

  /** Delete. */
  delete(): void {
    this.deleteEvent.emit({ id: this.announcementForm.value.id });
  }

  /** Show preview modal. */
  showPreview(): void {
    const previewModal = this.modalService.open(ModalPreviewAnnouncementComponent, {});

    tinyMCE.triggerSave();

    const title = this.announcementForm.controls.title.value;
    const body = tinyMCE.activeEditor.getContent();
    let titleClass = '';

    if (this.announcementForm.value.isEmergency) {
      titleClass = 'modal-announcement-title-' + this.announcementForm.value.alertColour.replace('#', '');
    }

    previewModal.componentInstance.title = title === '' ? 'TITLE GOES HERE' : title;
    previewModal.componentInstance.body = body === '' ? 'ANNOUNCEMENT GOES HERE' : body;
    previewModal.componentInstance.titleClass = titleClass;
  }

  /** Toggle published status and save. */
  togglePublishAndSave(): void {
    if (this.isEdit) {
      this.announcementForm.controls.isPublished.setValue(!this.announcementModel.isPublished);
    }
    else {
      this.announcementForm.controls.isPublished.setValue(true);
    }

    this.save({ type: 'Publish' });
  }

  /** Reset published toggle. */
  resetToggle(isCreating: boolean): void {
    if (isCreating) {
      this.announcementForm.controls.isPublished.setValue(false);
    }
    else {
      this.announcementForm.controls.isPublished.setValue(this.announcementModel.isPublished);
    }
  }

  /** Initialise alert colours. */
  initialiseAlertColours(): void {
    this.alertColours = this.enums.alertColours;
  }

}
