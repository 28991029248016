import { EventEmitter, Injectable, Output } from '@angular/core';
import { BaseRestService } from './rest.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { catchError } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { UserModel } from '../models/user.model'
import { AuthStatus } from '../models/auth-status.model';
import * as moment from 'moment';
import { AppConfigService } from './appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseRestService<any> {
  /** Emitter for components subscribing to the session */
  @Output() sessionEvent: EventEmitter<any> = new EventEmitter();

  /** Emitter for components subscribing to the site ID change event */
  @Output() siteIdChangeEvent: EventEmitter<any> = new EventEmitter();

  authKey = 'auth';
  roleType = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  nameType = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';

  constructor(protected http: HttpClient, private router: Router, protected appConfigService: AppConfigService) { 
    super(http, '', ''); 
  }

  login(username: string, password: string): any {
    const apiUrl = this.appConfigService.getConfig().apiUrl;
    return this.http.post(`${apiUrl}/token`, this.toFormEncodedString({ username, password }), { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
      .pipe(
        map(auth => {
          this.setAuth(auth);

          // Get and set the user's customer ID.
          this.http.get<UserModel>(`${this.adminUrl}users/byusername/${this.getAuthenticatedUser()}`)
            .pipe(catchError((err: Response) => {
              return observableThrowError(err);
            })).subscribe(user => {
              this.setCustomerId(user.customerId);
            });

          this.sessionEvent.emit({ isLoggedIn: true, username: this.getAuthenticatedUser() });

          return auth;
        })
      );
  }

  logout(): any {
    this.setAuth(null);
    this.setCustomerId('');
    this.setSiteId('');
    this.sessionEvent.emit({ isLoggedIn: false, username: '' });

    this.router.navigate(['./login']);
  }

  isAuthenticated(): AuthStatus {
    const token = localStorage.getItem(this.authKey);

    if (token === null) {
      return new AuthStatus(false);
    } else {
      // Check token expiry. If expiry has passed deny access.
      const decoded = jwt_decode<JwtPayload>(token);
      const expiry = decoded.exp;

      if (moment.unix(expiry) > moment.utc()) {
        return new AuthStatus(true);
      }
      else {
        return new AuthStatus(false, true, 'The session has expired.', 4000);
      }
    }
  }

  getAuth(): any {
    const i = localStorage.getItem(this.authKey);

    if (i) {
      return JSON.parse(i);
    }
    else {
      return null;
    }
  }

  getAuthenticatedUser(): string {
    const currentToken = localStorage.getItem(this.authKey);

    if (currentToken === null) {
      return '';
    } else {
      const decoded = jwt_decode<JwtPayload>(currentToken);
      return decoded[this.nameType];
    }
  }

  getAuthenticatedUserRoles(): Array<string> {
    const token = localStorage.getItem(this.authKey);

    if (token === null) {
      return [];
    } else {
      const decoded = jwt_decode<JwtPayload>(token);

      if (typeof decoded[this.roleType] === "object") {
        return decoded[this.roleType];
      } else {
        return [decoded[this.roleType]];
      }
    }
  }

  setAuth(auth: any): boolean {
    if (auth) {
      localStorage.setItem(this.authKey, JSON.stringify(auth));
    }
    else {
      localStorage.removeItem(this.authKey);
    }

    return true;
  }

  setCustomerId(customerId: string): any {
    localStorage.setItem('customerId', customerId);
  }

  getSiteId(): string {
    return sessionStorage.getItem('siteId');
  }

  setSiteId(siteId: string): any {
    sessionStorage.setItem('siteId', siteId);

    this.siteIdChangeEvent.emit({ siteId });
  }
}
