import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from  '@angular/common/http';
import { AuthService } from './services/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SiteService } from './services/site.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AnnouncementService } from './services/announcement.service';
import { EmergencyAnnouncementComponent } from './components/emergency-announcement/emergency-announcement.component';
import { FilteringComponent } from './components/filtering/filtering.component';
import { SortingComponent } from './components/sorting/sorting.component';
import { CommonService } from './services/common.service';
import { CommaSeparateStringToArrayPipe } from './pipe/comma-separate-string-to-array.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormButtonsComponent } from './components/common/form-buttons/form-buttons.component';
import { AnnouncementFormComponent } from './components/common/announcement-form/announcement-form.component';
import { Enums } from './enum/enums.component';
import { ModalPreviewAnnouncementComponent } from './components/modals/modal-preview-announcement/modal-preview-announcement.component';
import { EmergencyAnnouncementCreateComponent } from './components/emergency-announcement/emergency-announcement-create/emergency-announcement-create.component';
import { EmergencyAnnouncementEditComponent } from './components/emergency-announcement/emergency-announcement-edit/emergency-announcement-edit.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { GeneralAnnouncementComponent } from './components/general-announcement/general-announcement.component';
import { GeneralAnnouncementCreateComponent } from './components/general-announcement/general-announcement-create/general-announcement-create.component';
import { GeneralAnnouncementEditComponent } from './components/general-announcement/general-announcement-edit/general-announcement-edit.component';

import { AppConfigService } from './services/appconfig.service';

export function initConfig(appConfig: AppConfigService) {
    return () => appConfig.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    TopbarComponent,
    SidebarComponent,
    EmergencyAnnouncementComponent,
    FilteringComponent,
    SortingComponent,
    CommaSeparateStringToArrayPipe,
    FormButtonsComponent,
    AnnouncementFormComponent,
    ModalPreviewAnnouncementComponent,
    EmergencyAnnouncementCreateComponent,
    EmergencyAnnouncementEditComponent,
    SafeHtmlPipe,
    GeneralAnnouncementComponent,
    GeneralAnnouncementCreateComponent,
    GeneralAnnouncementEditComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },

    /** Services */
    AuthService,
    SiteService,
    AnnouncementService,
    CommonService,
    Enums,
    AppConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
