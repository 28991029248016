import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnnouncementModel } from '../models/announcement.model';
import { BaseRestService } from './rest.service';
import { PagedResultModel } from '../models/paged-result.model';
import { catchError } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AppConfigService } from './appconfig.service';

@Injectable()
export class AnnouncementService extends BaseRestService<AnnouncementModel> {
    constructor(protected http: HttpClient, protected appConfigService: AppConfigService) { 
        const apiUrl = appConfigService.getConfig().apiUrl;
        super(http, 'announcements', apiUrl); 
    }

    /** Paged emergency. */
    pagedEmergency(page: number, filterBy: string, filterValue: string, filterOperator: string, sortBy: string, sortByDirection: number, filterValueRange: string = ''): Observable<PagedResultModel> {
        return this.http.get<PagedResultModel>(`${this.adminUrl}/emergency?filterBy=${filterBy}&filterValue=${filterValue}&filterOperator=${filterOperator}&page=${page}&orderBy=${sortBy}&sortDirection=${sortByDirection}` + (filterValueRange !== '' ? `&filterValueRange=${filterValueRange}` : ''), { headers: new HttpHeaders().set('Content-Type', 'application/json') })
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Paged general. */
    pagedGeneral(page: number, filterBy: string, filterValue: string, filterOperator: string, sortBy: string, sortByDirection: number, filterValueRange: string = ''): Observable<PagedResultModel> {
        return this.http.get<PagedResultModel>(`${this.adminUrl}/general?filterBy=${filterBy}&filterValue=${filterValue}&filterOperator=${filterOperator}&page=${page}&orderBy=${sortBy}&sortDirection=${sortByDirection}` + (filterValueRange !== '' ? `&filterValueRange=${filterValueRange}` : ''), { headers: new HttpHeaders().set('Content-Type', 'application/json') })
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }
}
