import { Injectable } from '@angular/core';
import { BaseRestService } from './rest.service';
import { SiteModel } from '../models/site.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends BaseRestService<SiteModel> {
  constructor(protected http: HttpClient, protected appConfigService: AppConfigService) { 
    const apiUrl = appConfigService.getConfig().apiUrl;
    super(http, 'sites', apiUrl); 
  }
  
  /** Fetch by user roles, grouped by customer name. */
  fetchByUserRolesGroupedByCustomerName(): Observable<SiteModel[]> {
    return super.fetch('byuserroles/grouped');
  }
}
