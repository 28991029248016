import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'filtering',
  templateUrl: './filtering.component.html',
  styleUrls: ['./filtering.component.css']
})
export class FilteringComponent implements OnInit, OnChanges, AfterContentInit {
  /** Event to call the fetch method of the parent component */
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();

  /** Optional events to customize filter key or operators, and clear search of the parent component */
  @Output() filterKeyChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterOperatorChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() clearEvent: EventEmitter<any> = new EventEmitter();

  @Input() searchColumns: any;
  @Input() operators: any;
  @Input() defaultSearch: string;
  @Input() defaultOperator: string;
  @Input() customExclusions: any;
  @Input() fieldsForExclusion: Array<string>;

  public filterBy = '';
  public filterOperator = '';
  public filterValue = '';

  public isRangeEnabled = false;
  public filterValueRange = '';

  public filterValuePlaceHolder = 'Search value';

  constructor() { }

  /** Init. */
  ngOnInit(): void {

  }

   /** On changes. */
  ngOnChanges(): void {
      /** This is to handle Angular resetting the ngFor bindings after the search event. */
      this.onFilterChange();
  }

  /** After view init. */
  ngAfterContentInit(): void {
    this.filterBy = this.defaultSearch;

    this.filterOperator = this.defaultOperator;
  }

   /** Clears the current search parameters and reloads the collection. */
   clearSearch(): void {
    this.filterBy = this.defaultSearch;
    this.filterOperator = this.defaultOperator;
    this.filterValue = '';
    this.filterValueRange = '';

    if (this.clearEvent.observers.length > 0) {
        this.clearEvent.emit();
    }
    else {
        this.search();
    }
}

/** Triggers the parent fetch method. */
search(): void {
    if (this.searchEvent.observers.length > 0) {
        this.searchEvent.emit({ page: 1 });
    }

    this.onFilterChange();
}

/** Setter for filter value. */
setFilterValue(value: string): void {
    this.filterValue = value;
}

/** Setter for range value. */
setRangeValue(value: string): void {
    this.filterValueRange = value;
}

/** On filter key change */
onFilterChange(): void {
    /** Disable excluded operators for the current operator. */
    if (this.customExclusions !== undefined && this.customExclusions[this.filterBy]) {
        this.toggleOperators(true, this.customExclusions[this.filterBy]);
    }
    else {
        this.toggleOperators(false);
    }

    /** Change the operator if current selection has just been disabled. */
    const defaultOperator = this.operators.find(o => o.value === this.defaultOperator);
    const currentOperator = this.operators.find(o => o.value === this.filterOperator);
    let operatorToChangeTo = '';

    /** If current selection is not yet disabled, continue using it.  */
    if (!currentOperator?.disabled) {
        operatorToChangeTo = this.filterOperator;
     }
     /** If the default operator is disabled, find the first operator that's not disabled.  */
     else if (defaultOperator?.disabled) {
        operatorToChangeTo = this.operators.find(o => !o.disabled).value;
     }
     /** Default. */
     else {
        operatorToChangeTo = defaultOperator.value;
     }

    if (operatorToChangeTo !== '') {
         this.filterOperator = operatorToChangeTo;

         this.filterOperatorChangeEvent.emit(operatorToChangeTo);
     }

    /** Execute custom filter key changed behavior from parent. */
    if (this.filterKeyChangeEvent.observers.length > 0) {
        this.filterKeyChangeEvent.emit(this.filterBy);
    }

}

/** On operator change. */
onOperatorChange(): void {
    if (this.filterOperatorChangeEvent.observers.length > 0) {
        this.filterOperatorChangeEvent.emit(this.filterOperator);
    }
}

/** Toggle range search controls. */
toggleRangeSearching(isEnabled: boolean): void {
    this.isRangeEnabled = isEnabled;
    this.filterValuePlaceHolder = this.isRangeEnabled ? 'From' : 'Search value';
}

/** Toggle specific operators  */
toggleOperators(disable: boolean, operatorsToDisable: Array<string> = null): void {
    if (disable && operatorsToDisable != null) {
        for (const i of operatorsToDisable) {
            const operator = this.operators.find(op => op.value === i);
            if (operator != null) {
                operator.disabled = true;
            }
        }
    }
    else {
        for (const i of this.operators) {
            i.disabled = false;
        }
    }
}

}
