import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm;
  applicationName: string;

  isBusy: boolean;

  constructor(private authService: AuthService, private commonService: CommonService, private form: UntypedFormBuilder, private router: Router,  private toastr: ToastrService) {
    this.loginForm = form.group({
      userName: [''],
      password: ['']
    });

    this.applicationName = environment.applicationName;
  }

  login(e: any): void { 
    e.preventDefault();

    this.isBusy = true;

    this.commonService.toggleSpinner();

    this.authService.login(this.loginForm.value.userName, this.loginForm.value.password)
    .subscribe(res => {
      this.authService.getAuth();

      // Clear the customer and site IDs to make sure these aren't accessed by a user who shouldn't have access.
      this.authService.setCustomerId('');
      this.authService.setSiteId('');

      this.isBusy = false;

      this.router.navigate(['/']);

      this.toastr.success('User logged in successfully.', null, { closeButton: true });

      this.commonService.toggleSpinner(false);
  }, () => {
      this.isBusy = false;

      this.commonService.toggleSpinner(false);

      this.toastr.error('User login failed.', null, { closeButton: true });
  });
            

  }
}
