<div class="d-flex justify-content-end align-items-center flex-wrap flex-md-nowrap">
    <div class="col-2 pr-1 pl-0 pt-0">
        <select class="form-control w-100" id="filterBy" name="filterBy" [(ngModel)]="filterBy" (change)="onFilterChange()" required>
            <option *ngFor="let c of searchColumns" [ngValue]="c.value">{{c.text}}</option>
        </select>
    </div>
    <div class="col-2 pr-1 pl-0 pt-0">
        <select class="form-control w-100" id="filterOperator" name="filterOperator" [(ngModel)]="filterOperator" (change)="onOperatorChange()" required>
            <option *ngFor="let o of operators" [ngValue]="o.value" [disabled]="o.disabled">{{o.text}}</option>
        </select>
    </div>
    <div id="searchField" class="col-3 pr-1 pl-0 pt-0">
        <input (keyup.enter)="search()" class="form-control w-100" placeholder="{{filterValuePlaceHolder}}" type="text" id="filterValue" name="filterValue" [(ngModel)]="filterValue" autocomplete="off">
    </div>
    <div [hidden]="!isRangeEnabled" id="searchFieldRange" class="col-3 pr-1 pl-0 pt-0">
        <input  class="form-control w-100" placeholder="To" type="text" id="filterValueRange" name="filterValueRange" [(ngModel)]="filterValueRange" autocomplete="off">
    </div>
    <div class="pr-0 pl-0 pt-0">
        <button (click)="search()" type="button" class="btn btn-md btn-light mr-1"><img src="../../assets/images/icons/search.png" alt="Search" /></button>
        <a (click)="clearSearch()" class="btn btn-md btn-light"><img src="../../assets/images/icons/cross.png" alt="Close" /></a>
    </div>
</div>