export enum UserRole {
    Super = 'Super',
    Administrator = 'Administrator',
    Author = 'Author',
    DigitalSignage = 'DigitalSignage'
  }

export const UserRoles = [
    {name: 'Super', id: 1},
    {name: 'Administrator', id: 2},
    {name: 'Author', id: 3},
    {name: 'Digital signage', id: 4}
];
