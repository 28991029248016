import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private commonService: CommonService, private toastr: ToastrService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Check if a user is authenticated.
    var authenticated = this.authService.isAuthenticated();

    if (!authenticated.isValid) {
      if (authenticated.showError) {
        // Remove all instance of spinner.
        this.commonService.toggleSpinner(false);

        // Remove all instance of toasts.
        this.toastr.clear();
        this.toastr.error(authenticated.reason, null, { closeButton: true, timeOut: authenticated.errorTimeout });
      }

      this.router.navigate(['./login']);

      return false;
    }

    // Check if the authenticated user is allowed to access the route.
    const authenticatedUserRoles = this.authService.getAuthenticatedUserRoles();

    // If the route has roles set.
    if (next.data.roles) {
      // Loop through the authenticated user's roles.
      for (var u in authenticatedUserRoles) {
        // If one of the roles can access the route, return true;
        if (next.data.roles.indexOf(authenticatedUserRoles[u]) >= 0) {
          return true;
        }
      }
    } else {
      return true;
    }

    // If none of the roles can access the route, redirect to the access denided page.
    this.router.navigate(['/access-denied']);

    return false;
  }
}
