export enum AlertColour {
    Clear = 0,
    Yellow,
    Blue,
    Red
}

export const AlertColours = [
    {id: '#ffffff', name: 'Clear' },
    {id: '#ffff00', name: 'Yellow' },
    {id: '#0000ff', name: 'Blue' },
    {id: '#ff0000', name: 'Red' }
];
