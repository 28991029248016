import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnouncementFormComponent } from '../../common/announcement-form/announcement-form.component';
import { AnnouncementModel } from 'src/app/models/announcement.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'emergency-announcement-edit',
  templateUrl: './emergency-announcement-edit.component.html',
  styleUrls: ['./emergency-announcement-edit.component.css']
})
export class EmergencyAnnouncementEditComponent implements OnInit {
  @ViewChild(AnnouncementFormComponent) form: AnnouncementFormComponent;

  menuTitle = 'Content';
  controllerTitle = 'Emergency announcements';
  pageTitle = 'Edit emergency announcement';

  announcementModel: AnnouncementModel;
  validations: any;

  /** cTor. */
  constructor(private activatedRoute: ActivatedRoute, private announcementService: AnnouncementService, private commonService: CommonService, private router: Router) { }

  /** Init. */
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.announcementService.getById(params.id).subscribe(res => {
        this.announcementModel = res;
      }, (e) => {
        this.commonService.notifyError('Failed to load emergency announcement.', e);
      });
    });
  }

  /** Update. */
  update(formArguments: any): void {
    /** Clear any existing validation messages. */
    this.validations = undefined;

    const submitType = formArguments.type;

    // Convert to UTC before saving.
    formArguments.form.startDateTime = new Date(formArguments.form.startDateTime).toISOString();
    formArguments.form.endDateTime = new Date(formArguments.form.endDateTime).toISOString();

    this.announcementService.update(formArguments.form).subscribe(res => {
      this.commonService.notifySuccess('Emergency announcement updated successfully.');

      if (submitType === 'SaveCreate') {
        this.router.navigate(['/emergency-announcement/create']);
      }
      else if (submitType === 'SaveClose') {
        this.router.navigate(['/emergency-announcement']);
      }
      else if (submitType === 'Publish') {
        this.form.initialiseForm(false, res);
      }
      else {
        this.form.initialiseForm(true, res);
      }
    }, (e) => {
      if (e.error.errors === undefined || e.error.errors === null) {
        this.commonService.notifyError('Failed to update emergency announcement.', e);
      }
      /** Validation errors. */
      else {
        this.validations = e.error.errors;

        if (submitType === 'Publish') {
          this.form.resetToggle(false);
        }
      }
    });
  }

  /** Close. */
  close(): void {
    this.router.navigate(['/emergency-announcement']);
  }

  /** Delete */
  delete(formArguments: any): void {
    if (confirm('Are you sure you want to delete this record?')) {
      this.announcementService.delete(formArguments.id).subscribe(res => {
        this.commonService.notifySuccess('Emergency announcement deleted successfully.');
        this.router.navigate(['/emergency-announcement']);
      }, (e) => {
        this.commonService.notifyError('Failed to delete emergency announcement.', e);
      });
    }
  }
}
