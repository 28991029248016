
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/** Allows trusted DOM to avoid automatic sanitizing */
@Pipe({name: 'safehtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(html: any, hasStyle: any): SafeHtml {
    if (hasStyle) { return this.sanitizer.bypassSecurityTrustHtml(html); }
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }
}
