import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PagedResultModel } from '../../models/paged-result.model';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { AuthService } from 'src/app/services/auth.service';
import { FilteringComponent } from '../filtering/filtering.component';
import { SortingComponent } from '../sorting/sorting.component';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;

@Component({
  selector: 'emergency-announcement',
  templateUrl: './emergency-announcement.component.html',
  styleUrls: ['./emergency-announcement.component.css']
})
export class EmergencyAnnouncementComponent implements OnInit {
  @ViewChildren(FilteringComponent) filtering: QueryList<FilteringComponent>;
  @ViewChildren(SortingComponent) sorting: QueryList<SortingComponent>;

  menuTitle = 'Content';
  controllerTitle = 'Emergency announcements';

  currentPage = 1;
  defaultSorting: any = { sortBy: 'StartDateTime, EndDateTime', sortDirection: 1 };
  pagedEmergencyAnnouncements: PagedResultModel;
  siteId = '';

  /** cTor. */
  constructor(private announcementService: AnnouncementService, private authService: AuthService, private commonService: CommonService) {
    this.commonService.setTitle(this.controllerTitle);

    authService.siteIdChangeEvent.subscribe(res => {
      delete this.filtering;
      this.paged(this.defaultSorting);
    });
  }

  ngOnInit(): void {
    this.paged(this.defaultSorting);
  }

  /** Fetch paged emergency. */
  paged(searchArguments: any): void {
    this.siteId = this.authService.getSiteId();

    if (this.siteId === '') {
      delete this.pagedEmergencyAnnouncements;
    }
    else {
      this.commonService.toggleSpinner();

      this.currentPage = searchArguments.page === undefined ? this.currentPage : searchArguments.page;

      const filterQuery = this.commonService.parseFilterQuery(this.filtering, this.defaultSorting, searchArguments);

      this.announcementService.pagedEmergency(this.currentPage, filterQuery.filterBy, filterQuery.filterValue, filterQuery.filterOperator, filterQuery.sortBy, filterQuery.sortDirection, filterQuery.filterValueRange)
        .subscribe(res => {
          this.pagedEmergencyAnnouncements = res;
          this.commonService.toggleSpinner(false);
        }, (e) => {
          this.commonService.notifyError('Failed to load emergency announcements.', e);
          this.commonService.toggleSpinner(false);
        });
    }
  }

  /** On search clear. */
  clear(): void {
    this.currentPage = 1;

    this.toggleDateTimePickers(false);

    this.filtering?.first?.toggleRangeSearching(false);

    this.paged(this.defaultSorting);
  }

  /** Toggle date time pickers and disable unsupported operators for date. */
  toggleDateTimePickers(isDateTimeField: boolean): void {
    if (isDateTimeField) {
      $('#filterValue').datetimepicker({
        showOn: 'focus',
        dateFormat: 'dd M yy',
        dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        minDate: new Date().setHours(0, 0),
        timeFormat: 'h:mm TT',
        stepHour: 1,
        stepMinute: 5,
        ampm: true,
        onSelect: (dateText, inst) => {
          this.filtering?.first.setFilterValue(dateText);
        }
      });

      $('#filterValueRange').datetimepicker({
        showOn: 'focus',
        dateFormat: 'dd M yy',
        dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        minDate: new Date().setHours(0, 0),
        timeFormat: 'h:mm TT',
        stepHour: 1,
        stepMinute: 5,
        ampm: true,
        onSelect: (dateText, inst) => {
          this.filtering?.first.setRangeValue(dateText);
        }
      });

      this.filtering?.first?.toggleOperators(true, ['CaseInsensitiveDoesNotContain', 'IsEmpty', 'CaseInsensitiveEndsWith', 'CaseInsensitiveStartsWith', 'NotIsEmpty', 'IsEmpty']);
    }
    else {
      $('#filterValue').datetimepicker('destroy');

      $('#filterValueRange').datetimepicker('destroy');

      this.filtering?.first?.toggleOperators(false);
    }
  }

  /** On page change. */
  onPageChange(page: any): void {
    this.currentPage = page;
    const activeSort = this.sorting.first;
    this.paged({ sortBy: activeSort.currentSorting, sortDirection: activeSort.sortingDirection });
  }

  /** On filter key changed. */
  filterKeyChangeEvent(field: any): void {
    const isDateField = field === 'StartDateTime' || field === 'EndDateTime';

    this.filtering?.first?.toggleRangeSearching(this.filtering?.first?.filterOperator === 'Between' && isDateField);

    this.toggleDateTimePickers(isDateField);
  }

  /** On filter operator change. */
  filterOperatorChangeEvent(operator: any): void {
    const isDateField = this.filtering?.first?.filterBy === 'StartDateTime' || this.filtering?.first?.filterBy === 'EndDateTime';

    this.filtering?.first?.toggleRangeSearching(operator === 'Between' && isDateField);

    this.toggleDateTimePickers(isDateField);
  }

}
