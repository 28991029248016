import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteService } from 'src/app/services/site.service';
import * as jquery from 'jquery';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  authenticatedUserRoles = new Array<string>();
  siteId = '';
  sites: Array<any>;
  collapsedMenu: any;
  disableSiteSelectList = false;

  constructor(private authService: AuthService, private commonService: CommonService, private router: Router, private siteService: SiteService) {
    this.authenticatedUserRoles = this.authService.getAuthenticatedUserRoles();

    // When a site is created, updated or marked as inactive, re-fetch the sites to update the select list.
    commonService.siteChangeEvent.subscribe(res => {
      this.fetchSites();
  });
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof RoutesRecognized), map((event: RoutesRecognized) => {
      return event.state.root.firstChild.firstChild.data.menu;
    })).subscribe(customData => {
      if (customData !== undefined) {
        this.collapsedMenu = customData[0].toLowerCase();
      }
    });

    this.fetchSites();
  }

  ngAfterContentChecked(): void {
    this.disableSiteSelectList = (this.sites && this.sites.length === 1 && this.sites[0].length === 1) || this.router.url.indexOf('/create') >= 0 || this.router.url.indexOf('/channel/device') >= 0 || this.router.url.indexOf('/edit') >= 0 || this.router.url.indexOf('/view') >= 0;
  }

  fetchSites(): void {
    this.siteService.fetchByUserRolesGroupedByCustomerName()
      .subscribe(res => {
        this.sites = res;

        // If the user has only 1 sites assigned to them, set it as the selected site.
        if (this.sites.length === 1 && this.sites[0].length === 1) {
          this.authService.setSiteId(this.sites[0][0].id);
          this.siteId = this.sites[0][0].id;
        }
      }, (e) => {
        console.log(e);
        this.commonService.notifyError('Failed to load sites.', e);
      });
  }

  /** Is route active */
  isRouteActive(data: any[]): boolean {
    // Handle multiple unrelated routes.
    // Search using the root as the parent. Return if it finds a match.
    if (data.length > 1) {
      for (const route of Object.keys(data)) {
        const routeAsTree = new Array<any>();

        routeAsTree.push(data[route]);

        if (this.router.isActive(this.router.createUrlTree(routeAsTree, { relativeTo: null }), false)) {
          return true;
        }
      }
    }

    // Search using the first route as the parent.
    return this.router.isActive(this.router.createUrlTree(data), false);
  }

  setSiteId(): void {
    this.authService.setSiteId(this.siteId);

    // If no site has been selected, redirect to the root page and clear the collapsed menu.
    if (this.siteId === '') {
        this.router.navigate(['/']);
        delete this.collapsedMenu;
    }
}
}
