import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AccessGuard } from './guards/access.guard';
import { LoginGuard } from './guards/login.guard';
import { EmergencyAnnouncementComponent } from './components/emergency-announcement/emergency-announcement.component';
import { UserRole } from './enum/user-role.enum';
import { Menu } from './enum/menu.enum';
import { EmergencyAnnouncementCreateComponent } from './components/emergency-announcement/emergency-announcement-create/emergency-announcement-create.component';
import { EmergencyAnnouncementEditComponent } from './components/emergency-announcement/emergency-announcement-edit/emergency-announcement-edit.component';
import { GeneralAnnouncementComponent } from './components/general-announcement/general-announcement.component';
import { GeneralAnnouncementCreateComponent } from './components/general-announcement/general-announcement-create/general-announcement-create.component';
import { GeneralAnnouncementEditComponent } from './components/general-announcement/general-announcement-edit/general-announcement-edit.component';

const superAdministratorAuthorRoles = [UserRole.Super, UserRole.Administrator, UserRole.Author];

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      /** Emergency Announcement */
      {
        path: 'emergency-announcement',
        component: EmergencyAnnouncementComponent,
        canActivate: [AccessGuard],
        data: { menu: [Menu.Content], roles: superAdministratorAuthorRoles }
      },
      {
        path: 'emergency-announcement/create',
        component: EmergencyAnnouncementCreateComponent,
        canActivate: [AccessGuard],
        data: { menu: [Menu.Content], roles: superAdministratorAuthorRoles }
      },
      {
        path: 'emergency-announcement/edit/:id',
        component: EmergencyAnnouncementEditComponent,
        canActivate: [AccessGuard],
        data: { menu: [Menu.Content], roles: superAdministratorAuthorRoles }
      },
      /** General Announcement */
      {
        path: 'general-announcement',
        component: GeneralAnnouncementComponent,
        canActivate: [AccessGuard],
        data: { menu: [Menu.Content], roles: superAdministratorAuthorRoles }
      },
      {
        path: 'general-announcement/create',
        component: GeneralAnnouncementCreateComponent,
        canActivate: [AccessGuard],
        data: { menu: [Menu.Content], roles: superAdministratorAuthorRoles }
      },
      {
        path: 'general-announcement/edit/:id',
        component: GeneralAnnouncementEditComponent,
        canActivate: [AccessGuard],
        data: { menu: [Menu.Content], roles: superAdministratorAuthorRoles }
      },
    ],
    canActivate: [AccessGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
