import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnouncementFormComponent } from '../../common/announcement-form/announcement-form.component';
import { Router } from '@angular/router';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'general-announcement-create',
  templateUrl: './general-announcement-create.component.html',
  styleUrls: ['./general-announcement-create.component.css']
})
export class GeneralAnnouncementCreateComponent implements OnInit {

  @ViewChild(AnnouncementFormComponent) form: AnnouncementFormComponent;

  menuTitle = 'Content';
  controllerTitle = 'General announcements';
  pageTitle = 'Create general announcement';

  validations: any;

  /** cTor. */
  constructor(private commonService: CommonService, private announcementService: AnnouncementService, private router: Router) {
    this.commonService.setTitle(this.pageTitle);
  }

  /** Init. */
  ngOnInit(): void { }

  /** Create general announcement. */
  create(formArguments: any): void {
    /** Clear any existing validation messages. */
    this.validations = undefined;

    const submitType = formArguments.type;

    // Convert to UTC before saving.
    formArguments.form.startDateTime = new Date(formArguments.form.startDateTime).toISOString();
    formArguments.form.endDateTime = new Date(formArguments.form.endDateTime).toISOString();

    this.announcementService.create(formArguments.form).subscribe(res => {
      this.commonService.notifySuccess('General announcement created successfully.');

      if (submitType === 'SaveCreate') {
        this.form.initialiseForm(false);
      }
      else if (submitType === 'SaveClose') {
        this.router.navigate(['/general-announcement']);
      }
      else {
        this.router.navigate(['/general-announcement/edit', res.id]);
      }
    }, (e) => {
      if (e.error.errors === undefined || e.error.errors === null) {
        this.commonService.notifyError('Failed to create general announcement.', e);
      }
      /** Validation errors. */
      else {
        this.validations = e.error.errors;

        if (submitType === 'Publish') {
          this.form.resetToggle(true);
        }
      }
    });
  }

  /** Close. */
  close(): void {
    this.router.navigate(['/general-announcement']);
  }
}
