import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';

export class BaseRestService<T> {
    adminUrl: string;

    /** cTor. */
    constructor(protected http: HttpClient, url: string, apiUrl: string) {
        this.adminUrl = `${apiUrl}/admin/${environment.adminApiVersion}/${url}`;
    }

    /** Create entity. */
    create(model: T): Observable<T> {
        return this.http.post<T>(this.adminUrl, this.toFormEncodedString(model), { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Create entity with file. Include a 'file' object in the model to use. */
    createWithFile(model: T): Observable<T> {
        return this.http.post<T>(this.adminUrl, this.toFormData(model))
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Delete entity. */
    delete(id: string): Observable<T> {
        return this.http.delete<T>(`${this.adminUrl}/${id}`)
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Fetch entities. */
    fetch(path: string = ''): Observable<T[]> {
        return this.http.get<Array<T>>(this.adminUrl + (path === '' ? '' : `/${path}`))
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Get entity by ID */
    getById(id: string): Observable<T> {
        return this.http.get<T>(`${this.adminUrl}/${id}`)
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Update entity. */
    update(model: any): Observable<T> {
        return this.http.put<T>(this.adminUrl, this.toFormEncodedString(model), { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Update entity with file. Include a 'file' object in the model to use. */
    updateWithFile(model: T): Observable<T> {
        return this.http.put<T>(this.adminUrl, this.toFormData(model))
            .pipe(catchError((err: Response) => {
                return observableThrowError(err);
            }));
    }

    /** Convert object to form encoded string. */
    toFormEncodedString(data: any): string {
        let body = '';

        for (const key of Object.keys(data)) {
            if (body.length) {
                body += '&';
            }

            if (data[key] instanceof Array) {
                body += this.encodeUriArrayComponent(key, data);
            }
            else {
                if (data[key] !== null) {
                    body += `${key}=${encodeURIComponent(data[key])}`;
                }
            }
        }

        return body;
    }

    /** Convert array value to URI */
    encodeUriArrayComponent(key: string, data: any): string {
        let body = '';

        (data[key] as any[]).forEach(e => {
            if (e instanceof Array) { // If array value is also an array
                const index = (data[key] as any[]).findIndex(d => d === e);
                for (var i of e) {
                    body += `${key}[${index}]=${encodeURIComponent(i)}&`;
                }
            } else {
                body += `${key}=${encodeURIComponent(e)}&`;
            }
        });

        return body.slice(0, -1);
    }

    /** Convert form control to form data. */
    toFormData(data: any): FormData {
        const formData = new FormData();

        for (const key of Object.keys(data)) {
            const value = data[key];

            if ((key === 'file' || key === 'formFile') && value !== '') {
                formData.append(key, value, value?.name);
            } else if (value !== null) {
                // If the value is an object, parse it to be passed via form data.
                if (typeof value === "object") {
                    for (var i = 0; i < value.length; i++) {
                        formData.append(key + '[]', value[i]);
                    }
                } else {
                    formData.append(key, value);
                }
            }
        }

        return formData;
    }
}