import { EventEmitter, Injectable, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  /** Emitter for components subscribing to the site change event. */
  @Output() siteChangeEvent: EventEmitter<any> = new EventEmitter();

  constructor(private ngxSpinnerService: NgxSpinnerService, private title: Title, private toastrService: ToastrService) { }

  /** Notify error. */
  notifyError(message: string, e: any = ''): void {
    var error = message;

    // If an exception is passed in, add the details or the title to the error message.
    if (e !== '') {
      if (typeof e.error.detail !== "undefined") {
        error += `</br><i>- ${e.error.detail}</i>`;
      } else if (typeof e.error.title !== "undefined") {
        error += `</br><i>- ${e.error.title}</i>`;
      }
    }

    this.toastrService.error(error, '', { closeButton: true, enableHtml: true });

    // Log the full exception to the console to allow for further debugging.
    console.log(e);
  }

  /** Notify success. */
  notifySuccess(message: string): void {
    this.toastrService.success(message, '', { closeButton: true });
  }

  /** Parse filter query. */
  parseFilterQuery(filtering: any, sorting: any, searchArguments: any, intKeys: any = null): any {
    /** Common datetime column names. */
    const dateColumns: string[] = ['StartDateTime', 'EndDateTime', 'CreatedDate'];
    const emptyDateOperators: string[] = ['CaseInsensitiveEqualTo', 'CaseInsensitiveNotEqualTo'];

    let filterBy = filtering === undefined ? '' : filtering.first.filterBy;
    const filterValue = filtering === undefined ? '' : filtering.first.filterValue;
    let filterOperator = filtering === undefined ? '' : filtering.first.filterOperator;
    const filterValueRange = filtering === undefined ? '' : filtering.first.filterValueRange;
    const sortBy = searchArguments.sortBy === undefined ? sorting.sortBy : searchArguments.sortBy;
    const sortDirection = searchArguments.sortDirection === undefined ? sorting.sortDirection : searchArguments.sortDirection;

    /**
     * This is to get around an empty result issue when searching or sorting with a filter key of type int and the filter value is null.
     *  DynamicQuery will return empty results because an int property cannot be null or empty.
     *  Setting the filterBy to empty will skip this step.
     */
    if (intKeys !== null && intKeys.indexOf(filterBy) > -1 && searchArguments.sortBy !== undefined && filterValue === '') {
      filterBy = '';
    }

    /** If searching a date column and the filter value is empty, do not use a predicate. */
    if (dateColumns.indexOf(filterBy) > -1 && emptyDateOperators.indexOf(filterOperator) > -1 && filterValue === '') {
      filterBy = '';
      filterOperator = '';
    }

    return { filterBy, filterValue, filterOperator, sortBy, sortDirection, filterValueRange };
  }

  /** Sets the browser title of the calling component. */
  setTitle(newTitle: string): void {
    this.title.setTitle(newTitle);
  }

  /** Show loading spinner. */
  toggleSpinner(show: boolean = true): void {
    if (show) {
      this.ngxSpinnerService.show();
    }
    else {
      this.ngxSpinnerService.hide();
    }

    window.scrollTo(0, 0);
  }

  /** Datetime to local time zone. */
  dateTimeToLocal(date: any, timeFormat: string = ''): string {
    if (date === null) { return ''; }

    const utcObject = moment.utc(date).toDate();
    if (timeFormat !== '') {
        return moment(utcObject).format(`${environment.dateFormat} ${timeFormat}`);
    }

    return moment(utcObject).format(`${environment.dateFormat} ${environment.timeFormat}`);
}
}
