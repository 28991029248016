import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private inj: Injector, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.inj.get(AuthService);
        const authHeader = auth.getAuth();

        if (authHeader != null) {
            const siteId = auth.getSiteId();

            const authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${authHeader.access_token}`).set('siteid', siteId === null ? '' : siteId) });

            return next.handle(authReq).pipe(catchError(err => {
                if (err.status === 401) {
                    auth.setAuth(null);
                    this.router.navigate(['login']);
                    
                    return observableThrowError('Unauthorized');
                }
                else {
                    return observableThrowError(err);
                }
            }));
        }

        return next.handle(req);
    }
}
